import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import GalleryMatch from "../components/gallery-match/gallery-match"
import TabbedContent from "../components/tabbed-content/tabbled-content"

export default function Charity({ data }) {
  //Charity Page Data
  const charityData = data?.wpPage?.charity

  //Inner Hero Data
  const innerHero = charityData?.charityInnerHero

  //Match Gallery Data
  const matchData = charityData?.matchGallery

  //Tabbed Content Data
  const tabbedContent = charityData?.charityTabbedContent

  return (
    <>
     <DefaultLayout data={data}>
        <InnerPageHero innerData={innerHero} />
        <div className="rs-club" style={{paddingTop: "100px"}}>
          <div className="container">
            <h3 className="title-bg">{data.wpPage.title}</h3>
            <div className="row justify-content-md-center">
              <div className="col-lg-8 col-md-16">
                <div className="rs-club-text rich-text-editor">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <GalleryMatch matchData={matchData} /> */}
        {/* <TabbedContent charityIntroData={tabbedContent} /> */}
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      id
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      charity {
        matchGallery {
          componentTitle
          matchImages {
            instagramLink {
              url
            }
            matchImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 440, height: 320)
                }
                publicURL
              }
            }
            matchTitle
          }
        }
        charityInnerHero {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, height: 380)
              }
            }
          }
          leftButton {
            title
            url
          }
          rightButton {
            title
            url
          }
          title
        }
        charityTabbedContent {
          firstTabHeading
          firstTabDescription
          secondTabHeading
          secondTabDescription
          thirdTabHeading
          thirdTabDescription
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 534, width: 302)
              }
            }
          }
          nameClubMember
          positionInClub
        }
      }
    }
  }
`
